import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import DomandeObiezioni from "./landing/domandeObiezioni/DomandeObiezioni";
import "./index.css";


function App() {


  return (
    <div className="App">
        <Router>
            <Routes>
                <Route  path="/*" element={<Navigate to="/domande-obiezioni" replace/>} />
                <Route exact path="/domande-obiezioni" element={<DomandeObiezioni />} />
              
            </Routes>   
        </Router>
    </div>
  );
}

export default App;
