import React, { useState, useEffect } from "react";
import CallToAction from '../../components/CallToAction';
import ContactForm from '../../components/ContactForm';
import Reviews from './components/StepMobile';
import ReviewsDesktop from './components/StepDesktop';
import Accordion from "./components/Accordion";
import image2 from '../../assets/landing/landingDomandeObiezioni/sede.jpg';
import AOS from "aos";
import "aos/dist/aos.css";
import headVideo from "../../assets/landing/landingDomandeObiezioni/head.mp4";
import Recensioni from "../../components/reviews";
import NewsLetter from "../../components/Newsletter";
import NavbarDomandeObiezioni from "./components/NavbarDomandeObiezioni";
import Footer from "../../components/Footer";
import fallback from "../../assets/landing/landingDomandeObiezioni/header.png";
import { Helmet } from "react-helmet";
import "./landingDomandeObiezioni.css"



function LandingPrivate() {

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);


  const scrollButton = () => {
    const contactForm = document.querySelector("#contact-form");
    contactForm.scrollIntoView();
  };

  const scrollButtonAccordion = () => {
    const contactForm = document.querySelector("#accordionFlushExample");
    contactForm.scrollIntoView();
  };

  const breakpointMedium = 768;

    const [section2, setSection2] = useState(<p>Loading...</p>);
    useEffect(() => {
      function updateSection2() {
        if (window.innerWidth <= breakpointMedium) {
          setSection2(<Reviews/>);
        } else {
          setSection2(<ReviewsDesktop/>);
        }
      }
      window.addEventListener("resize", updateSection2);
      updateSection2();
      return () => window.removeEventListener("resize", updateSection2);
    }, []);


      const videoData = {
        "@context": "http://schema.org",
        "@type": "VideoObject",
        "name": "Lingotti",
        "description": "Video Lingotti",
        "thumbnailUrl": "https://careisgold.it/domande-obiezioni/static/media/header.899089b13ce1afb21cc7.png",
        "contentUrl": "https://careisgold.it/domande-obiezioni/static/media/head.01b963b33d88fa673c2b.mp4",
        "duration": "PT0M15S",
        "uploadDate": "2023-08-04",
        "category": "Presentazione"
      };
  
  return (
    <div id="domandeObiezioni">
    <Helmet>
    <title>Careisgold SpA: Domande e Obiezioni</title>
    <script type="application/ld+json">{JSON.stringify(videoData)}</script>
    </Helmet>
    <NavbarDomandeObiezioni/>
    <div className="bg-light">
      <div id="go-top">   
        <video width="100%" playsInline autoPlay muted loop poster={fallback}>
        <source src={headVideo} type="video/mp4" />
        Your browser does not support HTML5 video.
        </video>     
        <div className="col-10 text-overlay text-center">
        <p className="text-light lh-1 fw-bold">Sempre più italiani scelgono i 
        <span className="text-default-custom fst-italic"> lingotti d'oro</span> per proteggere i propri risparmi</p>
        </div>
        <div className="container-fluid py-md-5 pt-3">
          <div className="row justify-content-center align-items-center">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-12 col-md-8 text-center">
              <p className="text-default fw-bold fs-1 m-0 text-uppercase">Scopri le risposte ai dubbi più comuni</p>
              <button onClick={scrollButtonAccordion} className="border-0 bg-transparent ms-auto down-arrow">
                <i className="fa-solid fa-chevron-down text-default display-1"></i>
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid" id="accordionFlushExample">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-12 col-md-6 text-center">
            <Accordion/>
          </div>
          <div className="text-center col-12">
            <button className="bg-default border-0 text-light button-rounded mb-5 py-2 px-4 fw-normal fs-5" onClick={scrollButton} id="pianiOro">Contattaci</button>
          </div>
        </div>
      </div>

    <div className="container-fluid pb-5">
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-12 text-center">
            <p className="text-default fw-bold fs-1 text-center py-md-4 py-2">Dicono di noi</p>
            <Recensioni/>
          </div>
        </div>
      </div>
    </div>

      <div className="container-fluid bg-secondary bg-opacity-10">
        <div className="container py-5">
          <div className="row d-flex justify-content-around align-items-center">
            <div className="col-12 col-md-5 pb-md-0 px-0">
              <img className="img-fluid rounded" src={image2} alt="immagine sede"/>
            </div>
            <div className="col-11 col-md-5 mt-3 pb-md-0 pb-0">
              <p className="text-default fw-bold fs-1 text-center text-md-center m-0">Chi è Careisgold?</p>
              <p className="text-center lh-base m-0 ps-2 ps-md-0 text-dark">Careisgold è la società per azioni del Gruppo
              Care Holding SpA che <span className="fw-bold text-black text-opacity-75">offre ai propri clienti la
              possibilità di diventare proprietari di Oro Puro
              Fisico</span> da investimento con semplicità e sicurezza.</p>
              <div className="col-md-10 col-12 mx-auto">
                <ul className="my-4 text-dark">
                  <li>
                  È iscritta al registro di Banca d’Italia con codice Operatore Professionale Oro n. 5008578;
                  </li>
                  <li>
                  dispone della Licenza di Pubblica Sicurezza rilasciata dalla Questura;
                  </li>
                  <li>
                  ha siglato accordi esclusivi con le più prestigiose raffinerie di Oro Puro Fisico da investimento a garanzia del
                  miglior rapporto qualità prezzo;
                  </li>
                  <li>
                  ha un capitale sociale interamente versato di 2.000.000,00 €;
                  </li>
                </ul>  
              </div>
            </div>
          </div>
        </div>         
      </div>

      <NewsLetter/>

      <div className="container-fluid">
          <div className="row justify-content-center align-items-center">
            <div className="col-11 text-center py-4 py-md-5">
            <p className="text-default fs-2 fw-bold text-center m-0 p-0 text-uppercase">Non perdere l'opportunità di investire in oro puro fisico</p>
              <button onClick={scrollButton} className="border-0 bg-transparent ms-auto down-arrow">
                <i className="fa-solid fa-chevron-down text-default display-1"></i>
              </button>
            </div>
          </div>    
      </div>

      <div className="container-fluid">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-12">
              <section className="p-0">
                {section2}
              </section>  
            </div>
        </div>
      </div>
  
      <div className="container-fluid bg-light">
        <div className="row d-flex justify-content-center align-items-center">
            <div className="">
            <p className="pt-4 fs-2 fw-bold text-center text-default m-0"><em>Investire in oro fisico:<br/>la scelta vincente.</em></p>
            <p className="text-default fw-bold fs-1 text-center pt-4">CONTATTACI</p>
            </div>
            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-4 col-12 m-0 p-0">
            <ContactForm 
                NomeLanding='Landing domande obiezioni'
                Tipo='Cliente'
                BaseInputs={[    
                    { name: 'nome', required: true},
                    { name: 'cognome', required: true},
                    { name: 'email', required: true},
                    { name: 'telefono', required: true},
                ]}
                City={[{
                     city: true, 
                     required: true,
                     cityName: 'Città',
                     placeholder: 'Città'
                }]}
                DropDown={[{
                    dropDown: true, 
                    title:"Interessato/a a:", 
                    defaultOption: "Seleziona un'opzione", 
                    disabled: true, 
                    option1: 'Le nostre soluzioni',
                    option2: 'Collabora con noi',
                    option3: 'Altro',
                    required: true
                }]}
                Vote={[{
                    vote: false, 
                    name: "Quanto ti è piaciuto l'evento?", 
                    message: true, 
                    placeholder: 'Raccontaci la tua esperienza',
                    required: false
                }]}
                Message={[{
                    message: true, 
                    required: false,
                    messageName: 'Messaggio',
                    placeholder: 'altro...'
                }]}
                ConsensoDati={[{
                    consensoDati: true, 
                    required: true,
                    idPrivacy: 1
                }]}
                ConsensoNewsletter={[{
                    consensoNewsletter: false, 
                    listId: 0,
                    required: false,
                    idPrivacy: 3
                }]}
                SubmitButton={[{
                    submitName:'invia', 
                    submittedName:'inviato',
                    submittingName:'Sto inviando...'
                }]}
                SuccessMessage={[{
                    successMessage:'La tua richiesta è stata inoltrata con successo!', 
                    successButton:'Visita il nostro sito!', 
                    url:'https://www.careisgold.it/'
                }]}
            />
          </div>
        </div>
      </div>
        <CallToAction/>    
        <Footer/>
    </div>
    </div>
  )
}

export default LandingPrivate
