import React ,{ useState }from "react";
import axios from 'axios';
import PrivacyData from "../assets/privacy/cookie-privacy.json";
import { useInView } from 'react-intersection-observer';
import newsLetter from "../assets/newsletter/newsletter.webm";
import newsLetter1 from "../assets/newsletter/newsletter.gif";
import newsLetter2 from "../assets/newsletter/newsletter.mp4";
import newsLetterMobile from "../assets/newsletter/newsletter1.webm";
import newsLetterMobile1 from "../assets/newsletter/newsletter1.gif";
import newsLetterMobile2 from "../assets/newsletter/newsletter1.mp4";
const itemWithId3 = PrivacyData.find((item) => item.id === 3);


export default function Newsletter() {

    const [email, setEmail] = useState('');
    const [isSent, setIsSent] = useState(false);

    const API_KEY = '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'; 
    const LIST_ID = 21;

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
      };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        const email = event.target.email.value;
        addContactToList(email);
        setIsSent(true);
      };



    const addContactToList = async (email) => {
      const url = `https://api.brevo.com/v3/contacts`;
      const data = {
        email,
        listIds: [LIST_ID],
      };
      const headers = {
        'api-key': API_KEY,
        'Content-Type': 'application/json',
      };
    
      try {
        const response = await axios.post(url, data, { headers });
        console.log(response.data);

      } catch (error) {
  
        if (error.response && error.response.status === 400) {
          const brevoUrl = `https://api.brevo.com/v3/contacts/lists/${LIST_ID}/contacts/add`;
          const brevoData = {
            "emails":
            [
              `${email}`
            ]
            // listIds: [LIST_ID],
          };
          const brevoHeaders = {
            'api-key': API_KEY,
            'Content-Type': 'application/json',
          };
          try {
            const response = await axios.post(brevoUrl, brevoData, { headers: brevoHeaders });
            console.log(response.data);
          } catch (brevoError) {
            console.error(brevoError);
          }
        } else {
          console.error(error);
        }
      }
    };

    const [ref, inView] = useInView({
      triggerOnce: true, // Load the video only once when it comes into view
      threshold: 0.2, // Adjust the threshold as needed
    });

  return (
    <>
      <div className="pop-up-container">   
      <div ref={ref}>
      {inView ? (
        <>
            <video className="d-none d-md-block" width="100%" playsInline autoPlay muted loop>
            <source src={newsLetter} className="img-fluid" type="video/webm" />
            <source src={newsLetter1} className="img-fluid" type="video/gif" />
            <source src={newsLetter2} className="img-fluid" type="video/mp4" />
            Your browser does not support HTML5 video.
            </video>    
            <video className="d-block d-md-none" width="100%" playsInline autoPlay muted loop>
            <source src={newsLetterMobile} className="img-fluid" type="video/webm" />
            <source src={newsLetterMobile1} className="img-fluid" type="video/gif" />
            <source src={newsLetterMobile2} className="img-fluid" type="video/mp4" />
            Your browser does not support HTML5 video.
            </video>  
        </>
      ) : (
        <div className="background-newsletter bg-black bg-opacity-75">
          <div className="background-sfumato-bottom" style={{"height":"30rem"}}></div>
        </div>
      )}
    </div>
        <div className="container text-overlay-2 text-center mt-5">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 text-center">
              <p className="m-0 p-0 text-light d-none d-sm-block d-md-none lh-1 fw-bold display-5">Rimani sempre aggiornato sulle nostre promozioni</p>
              <p className="m-0 p-0 text-light d-none d-md-block d-lg-none lh-1 fw-bold fs-3">Rimani sempre aggiornato sulle nostre promozioni</p>
              <p className="m-0 p-0 text-light d-none d-lg-block d-xl-none lh-1 fw-bold fs-1">Rimani sempre aggiornato sulle nostre promozioni</p>
              <p className="m-0 p-0 text-light d-none d-xl-block lh-1 fw-bold display-4">Rimani sempre aggiornato sulle nostre promozioni</p>
              <p className="m-0 p-0 text-light d-block d-sm-none lh-1 fs-5">Rimani sempre aggiornato sulle nostre promozioni</p>
            </div>
            <div className="col-12 text-center mb-5 mt-4 my-sm-4 mt-md-4 mb-md-5 my-lg-4 my-xl-5">
            <form className="d-flex justify-content-center align-items-center" onSubmit={handleSubmit}>
            <div className="container">
                <div className="row d-flex justify-content-center align-items-center">

                <div className="col-md-6 col-10">
                    <input className="bg-white form-control rounded-pill" pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" type="text" value={email} id="email" name="email" autoComplete="email" placeholder="Inserisci qui la tua mail" onChange={handleEmailChange} required />
                </div>

                <div className="col-10 d-flex justify-content-center my-3 my-sm-3 my-md-3 my-lg-4">
                    <label className="switch me-3">
                        <input type="checkbox" id="consensoDati" className="form-check-input" required/>
                        <span className="slider round"/>
                    </label>
                    <a href="/" className="text-light text-decoration-none" data-bs-toggle="modal" data-bs-target="#modalNewsletter"><span className="text-danger">*</span> CONSENSO DATI</a>                                                                                                                   
                </div>
                    {!isSent && 
                        <div className="col-md-3 col-10 d-flex justify-content-center align-items-center">
                            <button type="submit" className="btn-default rounded px-4 py-1 py-md-2 px-md-5 text-uppercase">Invia</button>
                        </div>
                    }
                    {isSent &&             
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 alert text-center bg-light p-0 bg-opacity-50" role="alert" id="newsletterInviato">
                            <div className="bg-success bg-opacity-50 m-0 p-2">
                                <p className="fw-bold text-center m-0 text-shadow-none">
                                    La tua richiesta è stata inoltrata con successo!
                                </p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </form> 
        <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
                <div className="modal fade" id="modalNewsletter" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable d-flex justify-content-center align-items-center">
                        <div className="modal-content" style={{"width":"95%", "height":"70%"}}>
                            <div className="modal-header text-center">
                                <h6 className="modal-title text-default fw-bold fs-5" id="staticBackdropLabel">{itemWithId3.title}</h6>
                                <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-start text-shadow-none">
                              <div dangerouslySetInnerHTML={{ __html: itemWithId3.content }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


