import React from 'react'

function CallToAction() {

  return (
    <div className="col-3 col-md-2 col-xl-1 ms-auto text-end fixed-bottom ">
      <a className="text-light nav-link" rel="noreferrer" target="_blank" href="tel:0458213155">
        <button type="button" className="btn-call p-2 m-3 bg-default border-0 rounded-circle">
          <i className="fa-solid fa-phone-volume m-3 text-light fs-1"></i>
        </button>
      </a>
    </div>
  )

}

export default (CallToAction)