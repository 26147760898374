import React from "react";
import step1 from '../../../assets/landing/landingDomandeObiezioni/step-1.png';
import step2 from '../../../assets/landing/landingDomandeObiezioni/step-2.png';
import step3 from '../../../assets/landing/landingDomandeObiezioni/step-3.png';
import step4 from '../../../assets/landing/landingDomandeObiezioni/step-4.png';



export default function App() {
  

  return (

    <div>

    <div class="container text-center d-none d-lg-block">
        <div class="row justify-content-md-center align-items-center">
            <div class="col-2">
                <div className="text-center bg-dark bg-opacity-50 mx-auto rounded-circle" style={{"height":"125px", "width":"125px"}}>
                    <img className="img-fluid icon p-3" src={step1} alt="icona consulenza"/>
                </div>
            </div>
            <div class="col col-lg-1">
                <i class="fa-solid fa-arrow-right display-4 text-default mt-auto"></i>
            </div>
            <div class="col-2">
                <div className="text-center bg-dark bg-opacity-50 mx-auto rounded-circle" style={{"height":"125px", "width":"125px"}}>
                    <img className="img-fluid icon p-3" src={step2} alt="icona consulenza"/>
                </div>
            </div>
            <div class="col col-lg-1">
                <i class="fa-solid fa-arrow-right display-4 text-default mt-auto"></i>
            </div>
            <div class="col-2">
                <div className="text-center bg-dark bg-opacity-50 mx-auto rounded-circle" style={{"height":"125px", "width":"125px"}}>
                    <img className="img-fluid icon p-3" src={step3} alt="icona consulenza"/>
                </div>
            </div>
            <div class="col col-lg-1">
                <i class="fa-solid fa-arrow-right display-4 text-default mt-auto"></i>
            </div>
            <div class="col-2">
                <div className="text-center bg-dark bg-opacity-50 mx-auto rounded-circle" style={{"height":"125px", "width":"125px"}}>
                    <img className="img-fluid icon p-3" src={step4} alt="icona consulenza"/>
                </div>
            </div>
        </div>
    </div>

    <div className="container">
        <div className="row d-flex justify-content-center align-items-start">

            <div className="col-lg-3 col-6">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="d-flex justify-content-center text-center">
                        <div className="text-center bg-dark bg-opacity-50 mx-auto rounded-circle d-block d-lg-none" style={{"height":"125px", "width":"125px"}}>
                            <img className="img-fluid icon p-3" src={step1} alt="icona consulenza"/>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <p className="text-default m-0 fw-bold fs-2 text-center">STEP 1</p>
                        <p className="text-dark m-0 fw-bold fs-4 text-center lh-base">Consulenza a domicilio</p>
                    </div>
                    <div className="col-11 mt-3">
                        <p className="text-center text-dark">
                            La consulenza e la proposizione dell’offerta vengono effettuate presso il domicilio del cliente e conformemente alle sue necessità.
                        </p>
                    </div>
                </div>
            </div>


            <div className="col-lg-3 col-6">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="d-flex justify-content-center text-center">
                        <div className="text-center bg-dark bg-opacity-50 mx-auto rounded-circle d-block d-lg-none" style={{"height":"125px", "width":"125px"}}>
                            <img className="img-fluid icon p-3" src={step2} alt="icona consulenza"/>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <p className="text-default m-0 fw-bold fs-2 text-center">STEP 2</p>
                        <p className="text-dark m-0 fw-bold fs-4 text-center">Acquisto Oro</p>
                    </div>
                    <div className="col-11 mt-3">
                        <p className="text-center text-dark">
                        Una volta ricevuto l’ordine da parte del cliente, ci occupiamo dell’acquisto e dello
                        stoccaggio dell’Oro e liberiamo il cliente da
                        qualsiasi incombenza burocratica.
                        </p>
                    </div> 
                </div>
            </div>

            <div className="col-lg-3 col-6">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="d-flex justify-content-center text-center">
                        <div className="text-center bg-dark bg-opacity-50 mx-auto rounded-circle d-block d-lg-none" style={{"height":"125px", "width":"125px"}}>
                            <img className="img-fluid icon p-3" src={step3} alt="icona consulenza"/>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <p className="text-default m-0 fw-bold fs-2 text-center">STEP 3</p>
                        <p className="text-dark m-0 fw-bold fs-4 text-center">Custodia</p>
                    </div>
                    <div className="col-11 mt-3">
                        <p className="text-center text-dark">
                        L’Oro acquistato può
                        essere depositato presso
                        i caveaux Battistolli, i più
                        rinomati d’Italia. In
                        alternativa, organizziamo
                        il trasporto in 
                        totale sicurezza.                                                      
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-lg-3 col-6">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="d-flex justify-content-center text-center">
                        <div className="text-center bg-dark bg-opacity-50 mx-auto rounded-circle d-block d-lg-none" style={{"height":"125px", "width":"125px"}}>
                            <img className="img-fluid icon p-3" src={step4} alt="icona consulenza"/>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <p className="text-default m-0 fw-bold fs-2 text-center">STEP 4</p>
                        <p className="text-dark m-0 fw-bold fs-4 text-center">Gestione</p>
                    </div>
                    <div className="col-11 mt-3">
                        <p className="text-center text-dark">
                        Tramite la nostra App,
                        ogni cliente può
                        controllare lo stato del
                        proprio piano, i
                        versamenti effettuati, le
                        fatture emesse... e tanto
                        altro!
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>


  );
}


